<template>
    <div style="position: relative">
        <!--    <div class="block-title">-->
        <!--      <div class="fl"><i class="el-icon-arrow-left back" @click="goBack"></i>单位详情</div>-->
        <!--    </div>-->
        <div class="btn-container" v-if="activeName === '3'">
            <el-button size="small" type="primary" :loading="uploadLoading"
                       @click="() => {this.$router.push({name:'AddPosition',query:{id:this.$route.query.id}})}">
                新增楼宇和位置信息
            </el-button>
            <el-button size="small" type="primary" :loading="uploadLoading" @click="() => {$refs.upload_file.click()}">
                编辑楼宇和位置信息
            </el-button>
            <input class="upload_file" type="file" ref="upload_file" accept=".csv" @change="uploadFile"/>
        </div>
        <div class="btn-container" v-if="activeName === '4'">
            <el-button size="small" type="primary" @click="goDeviceList">查看设备列表</el-button>
        </div>
        <!--    <div class="btn-container" v-if="activeName === '5'">-->
        <!--      <el-button size="small" type="primary">单位全局配置</el-button>-->
        <!--    </div>-->
        <el-tabs v-model="activeName" type="card" @tab-click="changeTab">
            <el-tab-pane label="基本信息" name="1">
                <el-form label-position="left" class="common-form left_10" label-width="110px"
                         :model="departmentBaseInfo" :rules="departmentBaseInfoRules" ref="departmentBaseInfo">
                    <el-form-item label="单位名称：" prop="name">
                        <el-input class="small-input" v-model="departmentBaseInfo.name"
                                  placeholder="请输入单位名称"></el-input>
                    </el-form-item>
                    <el-form-item label="单位地址：" prop="location">
                        <el-select class="small-input" style="width: 100px" placeholder="省" @change="changeProvince"
                                   v-model="departmentBaseInfo.provinceId">
                            <el-option
                                    v-for="item in provinceList"
                                    :key="item.provinceId"
                                    :label="item.province"
                                    :value="item.provinceId">
                            </el-option>
                        </el-select>
                        <span class='left_5 right_5'>省</span>
                        <el-select class="small-input" style="width: 100px" placeholder="市" @change="changeCity"
                                   v-model="departmentBaseInfo.cityId">
                            <el-option
                                    v-for="item in cityList"
                                    :key="item.cityId"
                                    :label="item.city"
                                    :value="item.cityId">
                            </el-option>
                        </el-select>
                        <span class='left_5 right_5'>市</span>
                        <el-select class="small-input" style="width: 100px" placeholder="区" @change="changeDistrict"
                                   v-model="departmentBaseInfo.districtId">
                            <el-option
                                    v-for="item in districtList"
                                    :key="item.areaId"
                                    :label="item.area"
                                    :value="item.areaId">
                            </el-option>
                        </el-select>
                        <span class='left_5 right_5'>区</span>
                        <el-input class="small-input" placeholder="请输入具体地址"
                                  v-model="departmentBaseInfo.locationDetail"></el-input>
                    </el-form-item>
                    <el-form-item label="运营联系人：" prop="contacts">
                        <el-input class="small-input" v-model="departmentBaseInfo.contacts"
                                  placeholder="请输入运营联系人"></el-input>
                    </el-form-item>
                    <el-form-item label="手机号：" prop="telephone">
                        <el-input class="small-input" v-model="departmentBaseInfo.telephone"
                                  placeholder="请输入手机号"></el-input>
                    </el-form-item>
                </el-form>
                <div class="btn-list">
                    <el-button style="padding: 12px 50px" type="primary" :loading="departmentBaseLoading"
                               @click="saveDepartmentBaseInfo">保 存
                    </el-button>
                </div>
            </el-tab-pane>
            <el-tab-pane label="单位配置" name="2">
                <el-form label-position="left" class="common-form left_10" label-width="150px"
                         :model="departmentConfigInfo" :rules="departmentConfigRules" ref="departmentConfigInfo">
                    <el-form-item label="日间：" prop="freeTime">
                        <el-time-picker
                                class="small-input"
                                placeholder="起始时间"
                                format='HH:mm:ss'
                                style="height: 32px;width: 150px"
                                v-model="departmentConfigInfo.leisureStartTime">
                        </el-time-picker>
                        &nbsp;&nbsp;&nbsp;至&nbsp;&nbsp;&nbsp;
                        <el-time-picker
                                class="small-input"
                                placeholder="结束时间"
                                format='HH:mm:ss'
                                style="height: 32px;width: 150px"
                                v-model="departmentConfigInfo.leisureEndTime">
                        </el-time-picker>
                    </el-form-item>
                    <el-form-item label="夜间：" prop="busyTime">
                        <el-time-picker
                                class="small-input"
                                placeholder="起始时间"
                                format='HH:mm:ss'
                                style="height: 32px;width: 150px"
                                v-model="departmentConfigInfo.busyStartTime">
                        </el-time-picker>
                        &nbsp;&nbsp;&nbsp;至&nbsp;&nbsp;&nbsp;
                        <el-time-picker
                                class="small-input"
                                placeholder="结束时间"
                                format='HH:mm:ss'
                                style="height: 32px;width: 150px"
                                v-model="departmentConfigInfo.busyEndTime">
                        </el-time-picker>
                    </el-form-item>
                    <el-form-item label="陪护床可租时间：" prop="rentableTime">
                        <el-time-picker
                                class="small-input"
                                placeholder="起始时间"
                                format='HH:mm:ss'
                                style="height: 32px;width: 150px"
                                v-model="departmentConfigInfo.rentableStartTime">
                        </el-time-picker>
                        &nbsp;&nbsp;&nbsp;至&nbsp;&nbsp;&nbsp;
                        <el-time-picker
                                class="small-input"
                                placeholder="结束时间"
                                format='HH:mm:ss'
                                style="height: 32px;width: 150px"
                                v-model="departmentConfigInfo.rentableEndTime">
                        </el-time-picker>
                    </el-form-item>
                    <el-form-item label="消毒时长：" prop="disinfectionTime">
                        <el-input class="small-input" style="width: 150px" type="number" placeholder="请输入消毒时长"
                                  v-model="departmentConfigInfo.disinfectionTime"/>
                        <span class="left_5">分钟</span>
                    </el-form-item>
                    <el-form-item label="冷却时长：" prop="coolingTime">
                        <el-input class="small-input" style="width: 150px" type="number" placeholder="请输入冷却时长"
                                  v-model="departmentConfigInfo.coolingTime"/>
                        <span class="left_5">分钟</span>
                    </el-form-item>
                    <el-form-item label="过滤时间：" prop="filterTime">
                        <el-input class="small-input" style="width: 150px" type="number" placeholder="请输入过滤时间"
                                  v-model="departmentConfigInfo.filterTime"/>
                        <span class="left_5">分钟</span>
                    </el-form-item>
                </el-form>
                <div class="btn-list">
                    <el-button style="padding: 12px 50px" type="primary" :loading="configLoading"
                               @click="saveDepartmentConfig">保 存
                    </el-button>
                </div>
            </el-tab-pane>
            <el-tab-pane label="楼宇和位置信息" name="3">
                <div class="table-container">
                    <el-table
                            class="table"
                            height="100%"
                            v-loading="addressTableLoading"
                            :data="addressList">
                        <el-table-column
                                label="位置id"
                                prop="id"
                                align="center"
                                header-align="center"
                                :formatter="commonFormatter"
                        >
                        </el-table-column>
                        <el-table-column
                                label="楼宇"
                                prop="building"
                                align="center"
                                header-align="center"
                                :formatter="commonFormatter"
                        >
                        </el-table-column>
                        <el-table-column
                                label="楼层"
                                prop="floor"
                                align="center"
                                header-align="center"
                                :formatter="commonFormatter"
                        >
                        </el-table-column>
                        <el-table-column
                                label="位置"
                                prop="place"
                                align="center"
                                header-align="center"
                                :formatter="commonFormatter"
                        >
                        </el-table-column>
                        <el-table-column
                                align="center"
                                header-align="center"
                                label="操作">
                            <template slot-scope="scope">
                                <!--<el-button type="text" @click="goDetail(row)">编辑</el-button>-->
                                <el-button type="primary" size="mini" @click="goDetail(scope.row)"
                                           icon="el-icon-edit"></el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </el-tab-pane>
            <el-tab-pane label="设备信息" name="4">
                <el-form label-position="left" class="common-form left_10 show-form" label-width="100px"
                         :model="deviceInfo" ref="form">
                    <el-form-item label="租赁设备：" prop="rentNumber">
                        {{ deviceInfo.sum || 0 }}
                    </el-form-item>
                    <el-form-item label="异常设备：" prop="errorNumber">
                        <span style="color: #F56C6C">{{ deviceInfo.abnormalSum || 0 }}</span>
                    </el-form-item>
                </el-form>
            </el-tab-pane>
            <el-tab-pane label="商品配置" name="5">
                <div class="table-container">
                    <el-table
                            class="table"
                            height="100%"
                            v-loading="goodsConfigLoading"
                            :data="goodsConfigList">
                        <el-table-column
                                label="商品名称"
                                prop="goodsType"
                                align="center"
                                header-align="center"
                                :formatter="commonFormatter"
                        >
                            <template slot-scope="scope">
                                <span>{{ scope.row.goodsType === 0 ? '陪护床' : scope.row.goodsType === 1 ? '轮椅' : '' }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                                label="押金"
                                prop="depositAmount"
                                align="center"
                                header-align="center"
                                :formatter="commonFormatter"
                        >
                        </el-table-column>
                        <el-table-column
                                label="日间"
                                width="220"
                                align="center"
                                header-align="center"
                        >
                            <template slot-scope="{row}">
                                {{ row.leisureStart }} —— {{ row.leisureEnd }}
                            </template>
                        </el-table-column>
                        <el-table-column
                                label="夜间"
                                width="220"
                                align="center"
                                header-align="center"
                        >
                            <template slot-scope="{row}">
                                {{ row.busyStart }} —— {{ row.busyEnd }}
                            </template>
                        </el-table-column>
                        <el-table-column
                                label="消毒时间"
                                prop="disinfectionTime"
                                align="center"
                                header-align="center"
                                :formatter="commonFormatter"
                        >
                        </el-table-column>
                        <el-table-column
                                label="冷却时间"
                                prop="coolingTime"
                                align="center"
                                header-align="center"
                                :formatter="commonFormatter"
                        >
                        </el-table-column>
                        <el-table-column
                                label="操作"
                                align="center"
                                header-align="center"
                        >
                            <template slot-scope="{row}">
                                <el-button type="text" @click="goPriceDetail(row.id)">价格配置</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </el-tab-pane>
            <el-tab-pane label="分润配置" name="6">
                <el-form label-position="right" class="fengrun common-form left_10" label-width="100px"
                         :model="fzForm0" :rules="formDataRules" ref="fzForm0">
                    <div class="fz_class">
                        <span>陪护床</span>
                    </div>
                    <el-form-item label="日间：" prop="leisureStatus">
                        <el-radio-group v-model="fzForm0.leisureStatus">
                            <el-radio :label="0">金额（元）</el-radio>
                            <el-radio :label="1">百分比%</el-radio>
                        </el-radio-group>
                        <el-form-item style="display: inline-block" label="" prop="leisure">
                            <el-input class="small-input" style="width: 150px;margin-left: 20px" type="number"
                                      v-model="fzForm0.leisure" placeholder="请输入数值"/>
                        </el-form-item>
                    </el-form-item>
                    <el-form-item label="夜间：" prop="busyStatus">
                        <el-radio-group v-model="fzForm0.busyStatus">
                            <el-radio :label="0">金额（元）</el-radio>
                            <el-radio :label="1">百分比%</el-radio>
                        </el-radio-group>
                        <el-form-item style="display: inline-block" label="" prop="busy">
                            <el-input class="small-input" style="width: 150px;margin-left: 20px" type="number"
                                      v-model="fzForm0.busy" placeholder="请输入数值"/>
                        </el-form-item>
                    </el-form-item>
                </el-form>
                <el-form label-position="right" class="fengrun common-form left_10" label-width="100px"
                         :model="fzForm1" :rules="formDataRules" ref="fzForm1">
                    <div class="fz_class">
                        <span>轮椅</span>
                    </div>
                    <el-form-item label="日间：" prop="leisureStatus">
                        <el-radio-group v-model="fzForm1.leisureStatus">
                            <el-radio :label="0">金额（元）</el-radio>
                            <el-radio :label="1">百分比%</el-radio>
                        </el-radio-group>
                        <el-form-item style="display: inline-block" label="" prop="leisure">
                            <el-input class="small-input" style="width: 150px;margin-left: 20px" type="number"
                                      v-model="fzForm1.leisure" placeholder="请输入数值"/>
                        </el-form-item>
                    </el-form-item>
                    <el-form-item label="夜间：" prop="busyStatus">
                        <el-radio-group v-model="fzForm1.busyStatus">
                            <el-radio :label="0">金额（元）</el-radio>
                            <el-radio :label="1">百分比%</el-radio>
                        </el-radio-group>
                        <el-form-item style="display: inline-block" label="" prop="busy">
                            <el-input class="small-input" style="width: 150px;margin-left: 20px" type="number"
                                      v-model="fzForm1.busy" placeholder="请输入数值"/>
                        </el-form-item>
                    </el-form-item>
                </el-form>
                <el-form label-position="right" class="fengrun common-form left_10" label-width="100px"
                         :model="fzForm2" :rules="formDataRules" ref="fzForm2">
                    <div class="fz_class">
                        <span>急求推床</span>
                    </div>
                    <el-form-item label="日间：" prop="leisureStatus">
                        <el-radio-group v-model="fzForm2.leisureStatus">
                            <el-radio :label="0">金额（元）</el-radio>
                            <el-radio :label="1">百分比%</el-radio>
                        </el-radio-group>
                        <el-form-item style="display: inline-block" label="" prop="leisure">
                            <el-input class="small-input" style="width: 150px;margin-left: 20px" type="number"
                                      v-model="fzForm2.leisure" placeholder="请输入数值"/>
                        </el-form-item>
                    </el-form-item>
                    <el-form-item label="夜间：" prop="busyStatus">
                        <el-radio-group v-model="fzForm2.busyStatus">
                            <el-radio :label="0">金额（元）</el-radio>
                            <el-radio :label="1">百分比%</el-radio>
                        </el-radio-group>
                        <el-form-item style="display: inline-block" label="" prop="busy">
                            <el-input class="small-input" style="width: 150px;margin-left: 20px" type="number"
                                      v-model="fzForm2.busy" placeholder="请输入数值"/>
                        </el-form-item>
                    </el-form-item>
                </el-form>
                <el-form label-position="right" class="fengrun common-form left_10" label-width="100px"
                         :model="fzForm3" :rules="formDataRules" ref="fzForm3">
                    <div class="fz_class">
                        <span>无屏售卖</span>
                    </div>
                    <el-form-item label="日间：" prop="leisureStatus">
                        <el-radio-group v-model="fzForm3.leisureStatus">
                            <el-radio :label="0">金额（元）</el-radio>
                            <el-radio :label="1">百分比%</el-radio>
                        </el-radio-group>
                        <el-form-item style="display: inline-block" label="" prop="leisure">
                            <el-input class="small-input" style="width: 150px;margin-left: 20px" type="number"
                                      v-model="fzForm3.leisure" placeholder="请输入数值"/>
                        </el-form-item>
                    </el-form-item>
                    <el-form-item label="夜间：" prop="busyStatus">
                        <el-radio-group v-model="fzForm3.busyStatus">
                            <el-radio :label="0">金额（元）</el-radio>
                            <el-radio :label="1">百分比%</el-radio>
                        </el-radio-group>
                        <el-form-item style="display: inline-block" label="" prop="busy">
                            <el-input class="small-input" style="width: 150px;margin-left: 20px" type="number"
                                      v-model="fzForm3.busy" placeholder="请输入数值"/>
                        </el-form-item>
                    </el-form-item>
                </el-form>
                <div class="btn-list">
                    <el-button style="padding: 12px 50px" type="primary" :loading="configLoading"
                               @click="saveFzConfig">保 存
                    </el-button>
                </div>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
    import tableUse from "@/mixins/tableUse";
    import {resetMenuList, setMenuList} from "@/assets/js/common";

    export default {
        name: "UnitDetail",
        mixins: [tableUse],
        watch: {
            'departmentBaseInfo.locationProvince'(val) {
                this.departmentBaseInfo.location = val + this.departmentBaseInfo.locationDetail;
            },
            'departmentBaseInfo.locationCity'(val) {
                this.departmentBaseInfo.location = this.departmentBaseInfo.locationProvince + val + this.departmentBaseInfo.locationDetail;
            },
            'departmentBaseInfo.locationDistrict'(val) {
                this.departmentBaseInfo.location = this.departmentBaseInfo.locationProvince + this.departmentBaseInfo.locationCity + val + this.departmentBaseInfo.locationDetail;
                console.log(this.departmentBaseInfo.location)
            },
            'departmentBaseInfo.locationDetail'(val) {
                this.departmentBaseInfo.location = this.departmentBaseInfo.locationProvince + this.departmentBaseInfo.locationCity + this.departmentBaseInfo.locationDistrict + val;
            },
            'departmentConfigInfo.leisureStartTime'(val) {
                this.departmentConfigInfo.leisureStart = this.translationTime(val);
                this.departmentConfigInfo.freeTime = this.departmentConfigInfo.leisureStart + this.departmentConfigInfo.leisureEnd;
                // console.log("freeTime:"+this.departmentConfigInfo.freeTime)
            },
            'departmentConfigInfo.leisureEndTime'(val) {
                this.departmentConfigInfo.leisureEnd = this.translationTime(val);
                // console.log('val:'+val)
                this.departmentConfigInfo.freeTime = this.departmentConfigInfo.leisureStart + this.departmentConfigInfo.leisureEnd;
            },
            'departmentConfigInfo.busyStartTime'(newVal) {
                //监听departmentConfigInfo.busyStartTime值的改变
                console.log('newVal:' + newVal)
                this.departmentConfigInfo.busyStart = this.translationTime(newVal);
                this.departmentConfigInfo.busyTime = this.departmentConfigInfo.busyStartTime + this.departmentConfigInfo.busyStartTime;
            },
            'departmentConfigInfo.busyEndTime'(val) {
                console.log("开始：" + val)
                this.departmentConfigInfo.busyEnd = this.translationTime(val);
                this.departmentConfigInfo.busyTime = this.departmentConfigInfo.busyStartTime + this.departmentConfigInfo.busyEndTime;
                console.log("busyTime:" + this.departmentConfigInfo.busyTime)
            },
            'departmentConfigInfo.rentableStartTime'(newVal) {
                //console.log('newVal:' + newVal)
                this.departmentConfigInfo.rentableStart = this.translationTime(newVal);
            },
            'departmentConfigInfo.rentableEndTime'(val) {
                //console.log("开始：" + val)
                this.departmentConfigInfo.rentableEnd = this.translationTime(val);
            }
        },
        computed: {
            departmentId() {
                return this.$route.query.id
            },
            formDataRules() {
                return {
                    leisure: [
                        {required: true, message: '请输入正数', trigger: 'blur'},
                        {validator: this.validateAmount, trigger: 'blur'}
                    ],
                    busy: [
                        {required: true, message: '请输入正数', trigger: 'blur'},
                        {validator: this.validateAmount, trigger: 'blur'}
                    ]
                }
            },
            departmentBaseInfoRules() {
                return {
                    name: [
                        {required: true, message: '请输入单位名称', trigger: 'blur'},
                    ],
                    location: [
                        {required: true, message: '请设置单位地址'},
                        {validator: this.validateLocation}
                    ],
                    contacts: [
                        {required: true, message: '请输入运营联系人', trigger: 'blur'},
                    ],
                    telephone: [
                        {required: true, message: '请输入手机号', trigger: 'blur'},
                    ]
                }
            },
            departmentConfigRules() {
                return {
                    freeTime: [
                        {required: true, message: '请输入闲时长'},
                        {validator: this.validateFreeTime, trigger: 'blur'}
                    ],
                    disinfectionTime: [
                        {required: true, message: '请输入消毒时长', trigger: 'blur'},
                        {validator: this.validateTime, trigger: 'blur'}
                    ],
                    coolingTime: [
                        {required: true, message: '请输入冷却时长', trigger: 'blur'},
                        {validator: this.validateTime, trigger: 'blur'}
                    ],
                    filterTime: [
                        {required: true, message: '请输入过滤订单时长', trigger: 'blur'},
                        {validator: this.validateTime, trigger: 'blur'}
                    ],
                    busyTime: [
                        {required: true, message: '请输入忙时长'},
                        {validator: this.validateBusyTime, trigger: 'blur'}
                    ],
                }
            },
        },
        data() {
            return {
                activeName: '1',
                departmentBaseInfo: {
                    name: '',
                    locationProvince: '',
                    locationCity: '',
                    locationDistrict: '',
                    locationDetail: '',
                    provinceId: '',
                    cityId: '',
                    districtId: '',
                    location: '',
                    contacts: '',
                    telephone: ''
                },
                departmentBaseLoading: false,
                form: {
                    company: '',
                    position: '',
                    province: '',
                    city: '',
                    district: '',
                    address: '',
                    phone: '',
                    contact: '',
                    freeTime: [new Date(2021, 11, 23, 0, 0), new Date(2021, 11, 23, 23, 59)],
                    busyTime: [new Date(2021, 11, 23, 0, 0), new Date(2021, 11, 23, 23, 59)],
                    disinfectionTime: '',
                    freezeTime: '',
                    rentNumber: 3,
                    errorNumber: 1
                },
                departmentConfigInfo: {
                    freeTime: '',
                    busyTime: '',
                    disinfectionTime: '',
                    coolingTime: '',
                    leisureStart: '',
                    leisureEnd: '',
                    leisureStartTime: '',
                    filterTime: '',
                    busyStartTime: '',
                    busyEndTime: '',
                    busyStart: '',
                    busyEnd: '',
                    rentableStart: '',
                    rentableStartTime: '',
                    rentableEnd: '',
                    rentableEndTime: ''
                },
                configLoading: false,
                addressList: [],
                addressTableLoading: false,
                uploadLoading: false,
                deviceInfo: {},
                goodsConfigLoading: false,
                goodsConfigList: [],
                provinceList: [],
                cityList: [],
                districtList: [],
                isValidateLocation: false,
                isValidateFreeTime: false,
                isValidateBusyTime: false,
                fzForm0: {
                    id:null,
                    deviceType:0,
                    leisureStatus: null,
                    leisure: '',
                    busyStatus: null,
                    busy: ''
                },
                fzForm1: {
                    id:null,
                    deviceType:1,
                    leisureStatus: null,
                    leisure: '',
                    busyStatus: null,
                    busy: ''
                },
                fzForm2: {
                    id:null,
                    deviceType:2,
                    leisureStatus: null,
                    leisure: '',
                    busyStatus: null,
                    busy: ''
                },
                fzForm3: {
                    id:null,
                    deviceType:3,
                    leisureStatus: null,
                    leisure: '',
                    busyStatus: null,
                    busy: ''
                }
            }
        },
        methods: {
            goDetail(row) {
                this.$router.push({name: 'AddPosition', query: {id: this.$route.query.id, row: row}})
            },
            goBack() {
                this.$router.back();
            },
            validateAmount(rule, value, callback) {
                if (/^[0-9]+.?[0-9]*$/.test(value)) {
                    callback();
                } else {
                    callback(new Error("为正数"))
                }
            },
            validateLocation(rule, value, callback) {
                if (!this.isValidateLocation) callback();
                if (!this.departmentBaseInfo.provinceId || !this.departmentBaseInfo.cityId || !this.departmentBaseInfo.districtId) callback(new Error("请选择单位所在省市区"))
                if (!this.departmentBaseInfo.locationDetail) callback(new Error("请输入详细地址"));
                callback()
            },
            validateFreeTime(rule, value, callback) {
                if (!this.isValidateFreeTime) callback();
                if (!this.departmentConfigInfo.leisureStartTime) callback(new Error("请选择起始时间"));
                if (!this.departmentConfigInfo.leisureEndTime) callback(new Error("请选择结束时间"))
                callback();
            },
            validateBusyTime(rule, value, callback) {
                // if (!this.isValidateBusyTime) callback();
                if (!this.departmentConfigInfo.busyStartTime) callback(new Error("请选择起始时间"));
                if (!this.departmentConfigInfo.busyEndTime) callback(new Error("请选择结束时间"))
                callback();
            },
            translationTime(val) {
                if (val == null){
                    return val ;
                }
                let hour = val.getHours() < 10 ? '0' + val.getHours() : val.getHours() + '';
                let min = val.getMinutes() < 10 ? '0' + val.getMinutes() : val.getMinutes() + '';
                let seconds = val.getSeconds() < 10 ? '0' + val.getSeconds() : val.getSeconds() + '';
                console.log("translationTime:" + hour + ":" + min + ":" + seconds)
                return hour + ":" + min + ":" + seconds;
            },
            validateTime(rule, value, callback) {
                if (/^[0-9]+.?[0-9]*$/.test(value)) {
                    callback();
                } else {
                    callback(new Error("时长必须为正数"))
                }
            },
            changeTab() {
                switch (this.activeName) {
                    case "1": {
                        this.getDepartmentBaseInfo();
                        break;
                    }
                    case "2": {
                        this.getDepartmentConfig();
                        break;
                    }
                    case "3": {
                        this.getPositionList();
                        break;
                    }
                    case "4": {
                        this.getDeviceInfo();
                        break;
                    }
                    case "5": {
                        this.getGoodsConfigList();
                        break;
                    }
                    case "6": {
                        this.getFzConfig();
                        break;
                    }
                }
            },
            goPriceDetail(departmentId) {
                setMenuList({
                    path: '/priceDetail',
                    name: '价格配置'
                })
                this.$router.push({
                    name: 'PriceDetail',
                    query: {
                        id: departmentId
                    }
                })
            },
            getProvinceList() {
                this.$axios.get("/sys-api/city-api/provinceList", {}, (res) => {
                    if (res.code === '100') {
                        this.provinceList = res.data;
                    }
                })
            },
            getCityList() {
                this.$axios.get("/sys-api/city-api/cityList", {id: this.departmentBaseInfo.provinceId}, (res) => {
                    if (res.code === '100') {
                        this.cityList = res.data;
                    }
                })
            },
            getDistrictList() {
                this.$axios.get("/sys-api/city-api/areaList", {id: this.departmentBaseInfo.cityId}, (res) => {
                    if (res.code === '100') {
                        this.districtList = res.data;
                    }
                })
            },
            changeProvince(provinceId, isInit) {
                for (let i = 0; i < this.provinceList.length; i++) {
                    if (this.provinceList[i].provinceId === provinceId) {
                        this.departmentBaseInfo.locationProvince = this.provinceList[i].province;
                        break;
                    }
                }
                if (isInit) return
                this.departmentBaseInfo.cityId = "";
                this.departmentBaseInfo.locationCity = "";
                this.departmentBaseInfo.districtId = "";
                this.departmentBaseInfo.locationDistrict = "";
                this.getCityList();
            },
            changeCity(cityId, isInit) {
                for (let i = 0; i < this.cityList.length; i++) {
                    if (this.cityList[i].cityId === cityId) {
                        this.departmentBaseInfo.locationCity = this.cityList[i].city;
                        break;
                    }
                }
                if (isInit) return
                this.departmentBaseInfo.districtId = "";
                this.departmentBaseInfo.locationDistrict = "";
                this.getDistrictList();
            },
            changeDistrict(districtId) {
                for (let i = 0; i < this.districtList.length; i++) {
                    if (this.districtList[i].areaId === districtId) {
                        this.departmentBaseInfo.locationDistrict = this.districtList[i].area;
                        break;
                    }
                }
            },
            getDepartmentBaseInfo() {
                this.$axios.get("/sys-api/site-api/getDepartmentById", {id: this.departmentId}, (res) => {
                    if (res.code === '100') {
                        res.data.provinceId = res.data.locationProvince;
                        res.data.cityId = res.data.locationCity;
                        res.data.districtId = res.data.locationDistrict;
                        this.departmentBaseInfo = res.data || {};
                        this.getProvinceList();
                        this.getCityList();
                        this.getDistrictList();
                        this.changeProvince(this.departmentBaseInfo.provinceId, true);
                        this.changeCity(this.departmentBaseInfo.cityId, true);
                        this.changeDistrict(this.departmentBaseInfo.districtId)
                    }
                })
            },
            saveDepartmentBaseInfo() {
                this.isValidateLocation = true;
                this.$refs.departmentBaseInfo.validate((valid) => {
                    if (valid) {
                        this.isValidateLocation = false;
                        this.departmentBaseLoading = true;
                        let formData = {
                            id: this.departmentId,
                            name: this.departmentBaseInfo.name,
                            locationProvince: this.departmentBaseInfo.provinceId,
                            locationCity: this.departmentBaseInfo.cityId,
                            locationDistrict: this.departmentBaseInfo.districtId,
                            locationDetail: this.departmentBaseInfo.locationDetail,
                            contacts: this.departmentBaseInfo.contacts,
                            telephone: this.departmentBaseInfo.telephone
                        }
                        this.$axios.post("/sys-api/site-api/updateDepartment", formData, (res) => {
                            this.departmentBaseLoading = false;
                            if (res.code === '100') {
                                this.$message.success("更新单位基本信息成功");
                                this.getDepartmentBaseInfo();
                            }
                        })
                    } else this.isValidateLocation = false;
                })

            },
            getDepartmentConfig() {
                this.$axios.get("/sys-api/site-api/getDepartmentConfig", {id: this.departmentId}, (res) => {
                    if (res.code === '100') {
                        if (res.data && res.data.leisureStart) {
                            let startTime = res.data.leisureStart.split(":");
                            res.data.leisureStartTime = new Date(2021, 11, 23, Number(startTime[0]), Number(startTime[1]), Number(startTime[2]));
                        } else res.data.leisureStartTime = '';
                        if (res.data && res.data.leisureEnd) {
                            let endTime = res.data.leisureEnd.split(":");
                            res.data.leisureEndTime = new Date(2021, 11, 23, Number(endTime[0]), Number(endTime[1]), Number(endTime[2]));
                        }
                        if (res.data && res.data.busyStart) {
                            let startTime = res.data.busyStart.split(":");
                            res.data.busyStartTime = new Date(2021, 11, 23, Number(startTime[0]), Number(startTime[1]), Number(startTime[2]));
                        } else res.data.busyStartTime = '';
                        if (res.data && res.data.busyEnd) {
                            let endTime = res.data.busyEnd.split(":");
                            res.data.busyEndTime = new Date(2021, 11, 23, Number(endTime[0]), Number(endTime[1]), Number(endTime[2]));
                        }
                        if (res.data && res.data.rentableStart) {
                            let startTime = res.data.rentableStart.split(":");
                            res.data.rentableStartTime = new Date(2021, 11, 23, Number(startTime[0]), Number(startTime[1]), Number(startTime[2]));
                        } else res.data.rentableStartTime = '';
                        if (res.data && res.data.rentableEnd) {
                            let endTime = res.data.rentableEnd.split(":");
                            res.data.rentableEndTime = new Date(2021, 11, 23, Number(endTime[0]), Number(endTime[1]), Number(endTime[2]));
                        }
                        this.departmentConfigInfo = res.data || {};
                    }
                })
            },
            saveDepartmentConfig() {
                this.isValidateFreeTime = true;
                this.$refs.departmentConfigInfo.validate((valid) => {
                    if (valid) {
                        this.isValidateFreeTime = false;
                        this.configLoading = true;
                        let formData = {
                            departmentId: this.departmentId,
                            leisureStart: this.departmentConfigInfo.leisureStart,
                            leisureEnd: this.departmentConfigInfo.leisureEnd,
                            disinfectionTime: this.departmentConfigInfo.disinfectionTime,
                            busyEnd: this.departmentConfigInfo.busyEnd,
                            busyStart: this.departmentConfigInfo.busyStart,
                            coolingTime: this.departmentConfigInfo.coolingTime,
                            filterTime: this.departmentConfigInfo.filterTime
                        }
                        if (this.departmentConfigInfo.rentableStart != null){
                            formData.rentableStart= this.departmentConfigInfo.rentableStart
                        }
                        if (this.departmentConfigInfo.rentableEnd != null){
                            formData.rentableEnd= this.departmentConfigInfo.rentableEnd
                        }
                        this.$axios.postForm("/sys-api/site-api/departmentConfig", formData, (res) => {
                            console.log(formData)
                            this.configLoading = false;
                            if (res.code === '100') {
                                this.$message.success("单位配置成功");
                                this.getDepartmentConfig();
                            }
                            console.log(res)
                        })
                    } else this.isValidateFreeTime = false;
                })
            },
            saveFzConfig(){
                this.fzForm0.departmentId=this.departmentId;
                this.fzForm1.departmentId=this.departmentId;
                this.fzForm2.departmentId=this.departmentId;
                this.fzForm3.departmentId=this.departmentId;
                let fzArr = [];
                fzArr.push(this.fzForm0);
                fzArr.push(this.fzForm1);
                fzArr.push(this.fzForm2);
                fzArr.push(this.fzForm3);
                let fzFormData = {
                    list: fzArr
                }
                this.$axios.post("/sys-api/site-api/saveFzConfig", fzArr, (res) => {
                    if (res.code === '100') {
                        this.$message.success("保存成功");
                    }
                })
            },
            getFzConfig() {
                let _this = this;
                this.$axios.get("/sys-api/site-api/getFzConfig", {departmentId: this.departmentId}, (res) => {
                    if (res.code === '100') {
                        res.data.forEach(function (data) {
                            if (data.deviceType==0){
                                _this.fzForm0 = data;
                            }
                            if (data.deviceType==1){
                                _this.fzForm1 = data;
                            }
                            if (data.deviceType==2){
                                _this.fzForm2 = data;
                            }
                            if (data.deviceType==3){
                                _this.fzForm3 = data;
                            }
                        })
                    }
                })
            },
            getPositionList() {
                this.addressTableLoading = true;
                this.$axios.get('/sys-api/site-api/getPosition', {departmentId: this.departmentId}, (res) => {
                    this.addressTableLoading = false;
                    if (res.code === '100') {
                        this.addressList = res.data;
                    }
                })
            },
            uploadFile() {
                this.uploadLoading = true;
                let file = this.$refs.upload_file.files[0];
                let extName = file.name.substring(file.name.lastIndexOf(".")).toLowerCase();
                if (extName.indexOf('csv') === -1) {
                    this.$message.error("只能上传csv文件");
                    return
                }
                let formData = new FormData();
                formData.append("departmentId", this.departmentId);
                formData.append("file", file);
                this.$axios.form("/sys-api/site-api/locationFile", formData, (res) => {
                    this.uploadLoading = false;
                    if (res.code === '100') {
                        this.$message.success("上传位置信息成功");
                        this.getPositionList();
                    }
                })
            },
            getDeviceInfo() {
                this.$axios.get("/sys-api/site-api/getDevice", {departmentId: this.departmentId}, (res) => {
                    if (res.code === '100') {
                        this.deviceInfo = res.data || {};
                    }
                })
            },
            goDeviceList() {
                this.$router.push({
                    name: 'DeviceList',
                    params: {
                        departmentName: this.departmentBaseInfo.name
                    }
                })
            },
            getGoodsConfigList() {
                this.goodsConfigLoading = true;
                this.$axios.get("/sys-api/site-api/departmentGoods", {id: this.departmentId}, (res) => {
                    this.goodsConfigLoading = false;
                    if (res.code === '100') {
                        this.goodsConfigList = res.data;
                        //res.data.name = '陪护床';
                        //this.goodsConfigList.push(res.data);
                    }
                })
            },
        },
        mounted() {
            let menuList = [
                {
                    name: '场地管理',
                    path: ''
                },
                {
                    name: '场地列表',
                    path: '/site',
                },
                {
                    name: "单位详情",
                    path: "/unitDetail"
                }
            ]
            resetMenuList(menuList)
            this.getDepartmentBaseInfo();
        }
    }
</script>

<style scoped lang="scss">
    .el-tabs {
        padding: 10px;
        height: calc(100% - 20px);
    }

    ::v-deep .el-tabs__content {
        height: calc(100% - 56px - 15px);
        overflow: auto;
    }

    .el-tab-pane {
        height: 100%;
    }

    .small-input {
        width: 300px;
    }

    .btn-list {
        margin-left: 100px;
        margin-top: 20px;
    }

    .el-range-editor.el-input__inner {
        padding: 0 10px;
    }

    ::v-deep input.el-input__inner::-webkit-outer-spin-button,
    ::v-deep input.el-input__inner::-webkit-inner-spin-button {
        -webkit-appearance: none !important;
    }

    ::v-deep input.el-input__inner[type="number"] {
        -moz-appearance: textfield !important;
    }

    .table-container {
        padding: 0;
        height: 100%;
    }

    .btn-container {
        position: absolute;
        top: 13px;
        right: 20px;
        z-index: 2;

        .upload_file {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            visibility: hidden;
        }
    }

    .fz_class {
        font-size: 18px;
        margin-left: 10px;
        margin-bottom: 10px;
        font-weight: bold;
    }

    .fz_input {
        display: inline-block;
    }

    .fengrun {
        ::v-deep .el-form-item__error {
            margin-left: 20px !important;
        }
    }
</style>
